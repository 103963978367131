import React, { useState, useEffect } from "react";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";
import {
  FlexColumnCont90x95Percent,
  FlexColumnCont95x30Percent,
  FlexColumnContGreenBorderTopRounded550pxHeight,
  FlexRowCont,
} from "../../StylesheetComps/Cont";
import {
  LandscapePara,
  MediumStyledImage,
  SmallHeaderLeagueSpartan,
} from "../../StylesheetComps/LandscapeForm";
import { FixedOverlay200vh } from "../../StylesheetComps/OverlayCont";
import { AddCasePhoto } from "../../API_Functions/CaseQuote_Functions";
import { AddImageToCloud } from "../../API_Functions/Utility_Functions";
import { Icon20x20 } from "../../StylesheetComps/Imgs/Icons";
import { LinkText } from "../../StylesheetComps/AuthSheet";
import { useParams } from "react-router-dom";
import { FlexDivCenter } from "../../StylesheetComps/Div";

const ProjectPhotoUploadPreviewWindow = ({
  open,
  CloseWindow, // The callback function to close the window
  CancelUpload,
  quoteObj,
  UploadSuccessful,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]); // Store image preview URLs here
  const [imageCaptions, setImageCaptions] = useState({});
  const [progress, setProgress] = useState({});
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const params = useParams();

  // Handle file selection and generate image previews
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);

    // Generate image preview URLs once and store them in state
    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  // Cleanup created object URLs when component unmounts
  useEffect(() => {
    return () => {
      imagePreviews.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [imagePreviews]);

  // Handle caption change
  const handleCaptionChange = (index, value) => {
    setImageCaptions((prev) => ({ ...prev, [index]: value }));
  };

  // Function to reset all state variables
  const resetState = () => {
    setSelectedFiles([]);
    setImagePreviews([]);
    setImageCaptions({});
    setProgress({});
    setUploadSuccess(false);
  };

  // Function to upload photos one by one using FormData
  const uploadPhotos = async () => {
    setUploadSuccess(false); // Reset success state

    // Iterate over selected files and upload them one by one
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const caption = imageCaptions[i] || ""; // Get the corresponding caption

      try {
        // Step 1: Create FormData to upload the image
        let formData = new FormData();
        formData.append("Overlay Photo", file);

        // Step 2: Upload image to the cloud using FormData
        const cloudImageUrl = await AddImageToCloud(formData);

        if (cloudImageUrl) {
          // Step 3: Bind the uploaded image to the case using AddCasePhoto
          const photoData = {
            Caption: caption,
            File: cloudImageUrl, // Cloud image URL
            Case_Id: params.caseId, // Use case ID from URL params
          };

          await AddCasePhotoWithProgress(photoData, i);
        } else {
          console.error(`Failed to upload ${file.name} to cloud`);
        }
      } catch (error) {
        console.error(`Error uploading photo ${file.name}:`, error);
      }
    }

    setUploadSuccess(true); // Set success message when all photos are uploaded

    // Reset state and close window after successful upload
    resetState();
    CloseWindow();
  };

  // Function to upload photo with progress tracking
  const AddCasePhotoWithProgress = async (photoData, index) => {
    return AddCasePhoto(photoData).then((resp) => {
      if (resp) {
        // Mocking progress to simulate upload completion for UI update
        setProgress((prevProgress) => ({
          ...prevProgress,
          [index]: 100, // Assuming 100% upload completion
        }));
      }
    });
  };

  return open ? (
    <FixedOverlay200vh>
      <FlexColumnContGreenBorderTopRounded550pxHeight height="auto" overflowy="scroll">
        <FlexRowCont height="100%" justifycontent="flex-start" alignitems="baseline">
          <FlexColumnCont90x95Percent>
            <SmallHeaderLeagueSpartan>Upload Project Photos</SmallHeaderLeagueSpartan>

            {/* File Upload Section */}
            <input type="file" multiple onChange={handleFileSelect} accept="image/*" />

            {imagePreviews.map((previewUrl, index) => (
              <div key={index}>
                <FlexDivCenter margin="16px">
                  <MediumStyledImage
                    objfit="cover"
                    height="150px"
                    width="150px"
                    mobileheight="20vh"
                    mobilewidth="40vw"
                    src={previewUrl} // Use the preview URL from state
                    alt={`preview-${index}`}
                  />
                  <LandscapePara
                    mobilewidth="68vw"
                    mobileheight="25vh"
                    height="auto"
                    maxLength="250"
                    margin="8px"
                    value={imageCaptions[index] || ""}
                    onChange={(e) => handleCaptionChange(index, e.target.value)}
                    placeholder="Enter photo description"
                  />
                </FlexDivCenter>
                {/* Progress Bar */}
                {progress[index] !== undefined && (
                  <div>
                    <progress value={progress[index]} max="100" />
                    <span>{progress[index]}%</span>
                  </div>
                )}
              </div>
            ))}

            <FlexRowCont justifycontent="flex-end">
              <LinkText onClick={()=>{CloseWindow();}}>Cancel</LinkText>
              <RegularResponsiveButton onClick={uploadPhotos}>Upload</RegularResponsiveButton>
            </FlexRowCont>

            {/* Success Message */}
            {uploadSuccess && (
              <div>
                <SmallHeaderLeagueSpartan>Photos uploaded successfully!</SmallHeaderLeagueSpartan>
              </div>
            )}
          </FlexColumnCont90x95Percent>

          {/* Close Icon */}
          <Icon20x20 onClick={()=>{CloseWindow();}} src="/Close.svg"></Icon20x20>
        </FlexRowCont>
      </FlexColumnContGreenBorderTopRounded550pxHeight>
    </FixedOverlay200vh>
  ) : null;
};

ProjectPhotoUploadPreviewWindow.defaultProps = {
  CloseWindow: () => {}, // Default to an empty function in case it's not passed
  CancelUpload: () => {},
  UploadSuccessful: () => {},
};

export default ProjectPhotoUploadPreviewWindow;
