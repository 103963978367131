// Importing React and hooks
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
// Importing API functions
import { CaseStatus } from "../../API_Functions/CaseQuote_Functions";

// Importing stylesheet components
import { ColorPalette } from "../../StylesheetComps/Colors";
import { FlexZoomSpaceBetween, FlexCont76PercentMobile, DivWithDisplay, DivWithMarginPadding } from "../../StylesheetComps/Cont";
import { Div150pxMobile250px, Div150pxMobileDisplayNone, Div80px, StyledDiv } from "../../StylesheetComps/Div";
import { Icon30px20pxPointer } from "../../StylesheetComps/Quote";
import { RegularText, TitleFontSize100PercentPointer } from "../../StylesheetComps/Titles";
import { UserRole } from "../../StylesheetComps/UserRoles";
import moment from "moment";
import { RegularResponsiveButton } from "../../StylesheetComps/Components";

const CaseCardContainer = styled(DivWithDisplay)`
  padding: 10px;
  background-color: ${({ isOdd }) => (isOdd ? '#FAFAFA' : '#FFFFFF')};
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    padding: 20px;
    background-color: #F5F5F7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const CaseCard = ({index, CaseNumber, DateText, ClientName, Status, CaseId, onDel, StatusText, StatusColor, EstimatorName, isOdd, Address}) => {

  const [roleNum, setRoleNum] = useState(UserRole.Loading); 
  const { Id, storeId } = useParams();
  const history = useHistory();
  const projectpath = `/viewcase/${Id}/${storeId}/${CaseId}`
  
  const GetProfileInfo = async()=>{ 
    setRoleNum(JSON.parse(sessionStorage.getItem('userInfo')).Role)
  }
  
  useEffect(() => { 
    GetProfileInfo();
  }, []) 

      if (roleNum === UserRole.Manager || roleNum === UserRole.Admin || roleNum === UserRole.Estimator) {
        switch (Status) {
          case CaseStatus.Drafted:
              StatusText = ("Drafted")
              StatusColor = ("grey")
            break;
            case CaseStatus.Submitted:
              StatusText = ("Submitted")
              StatusColor = ("rgb(0, 199, 239)")
              break;
              case CaseStatus.Accepted:
                StatusText = ("Assigned")
                StatusColor = ('rgb(98, 36, 217)')
                break;
                case CaseStatus.Processed:
                  StatusText = ("Estimated")
                  StatusColor = (ColorPalette.PrimaryButtonBlue)
                  break;
                  case CaseStatus.PayPending:
                      StatusText = ("Accepted Project")
                      StatusColor = ('rgb(241, 126, 173, 1)')
                      break;
                  case CaseStatus.Construction:
                    StatusText = ("In-Progress")
                    StatusColor = ('rgb(100, 198, 162, 1)')
                    break;
                  case CaseStatus.Closed:
                    StatusText = ("Closed")
                    StatusColor = ("#263238")
                    break;
                    case CaseStatus.CancelRequested:
                      StatusText = ("Cancel Requested")
                      StatusColor = ("#F53838")
                      break;
                      case CaseStatus.Canceled:
                        StatusText = ("Cancelled")
                        StatusColor = ("#F53838")
                        break;
                        default:
                          StatusText = ("Draft")
                          StatusColor = ("grey")
                      }
      }

     
    // return <DivWithDisplay bgcolor='white' width="80%" onClick={()=>{history.push(projectpath)}}>
    //   <DivWithDisplay justifycontent="space-between" flexdirection="row">
    //     <DivWithDisplay width="20px"></DivWithDisplay>
    //   {/* <DivWithDisplay width="80px">
    // <TitleFontSize100PercentPointer >{index}</TitleFontSize100PercentPointer>
    // </DivWithDisplay> */}
    // <Div150pxMobile250px>
    // <TitleFontSize100PercentPointer>{CaseNumber}</TitleFontSize100PercentPointer>
    // </Div150pxMobile250px>
    // <Div150pxMobile250px>
    // <TitleFontSize100PercentPointer>{ClientName}</TitleFontSize100PercentPointer>
    // </Div150pxMobile250px>
    // <Div150pxMobileDisplayNone>
    // <TitleFontSize100PercentPointer>{moment(DateText).format("DD/MM/YYYY")}</TitleFontSize100PercentPointer>
    // </Div150pxMobileDisplayNone>
    // <Div150pxMobile250px>
    // <TitleFontSize100PercentPointer>{EstimatorName}</TitleFontSize100PercentPointer>
    // </Div150pxMobile250px>
    // <Div150pxMobile250px>
    // <RegularText color={StatusColor}> {StatusText} </RegularText>
    // </Div150pxMobile250px>
    // <Div150pxMobile250px>
    // <Icon30px20pxPointer margintop={'18px'} src='/Garbage_Icon.svg' onClick={onDel}></Icon30px20pxPointer>
    // </Div150pxMobile250px>
    //   </DivWithDisplay>
    // </DivWithDisplay>

    return (
      <CaseCardContainer isOdd={isOdd} width="90%" onClick={() => { history.push(projectpath) }}>
      <DivWithDisplay justifycontent='space-evenly' width="100%" onClick={() => { history.push(projectpath) }}>
        <DivWithDisplay justifycontent='space-evenly' flexdirection="row">
      
          <StyledDiv width="12vw" mobileWidth="250px">
            <TitleFontSize100PercentPointer>{ClientName}</TitleFontSize100PercentPointer>
          </StyledDiv>
              <StyledDiv width="12vw" mobileWidth="250px">
            <TitleFontSize100PercentPointer>{Address}</TitleFontSize100PercentPointer>
          </StyledDiv>
          <StyledDiv width="12vw" mobileDisplay="none">
            <TitleFontSize100PercentPointer>{moment(DateText).format("DD/MM/YYYY")}</TitleFontSize100PercentPointer>
          </StyledDiv>
          <StyledDiv width="12vw" mobileWidth="250px">
            <TitleFontSize100PercentPointer>{EstimatorName}</TitleFontSize100PercentPointer>
          </StyledDiv>
          <StyledDiv width="12vw" mobileWidth="250px">
            <RegularResponsiveButton bgcolor={StatusColor}>{StatusText}</RegularResponsiveButton>
          </StyledDiv>
          <StyledDiv width="5vw" mobileWidth="250px">
            {/* <Icon30px20pxPointer margintop={'18px'} src='/Garbage_Icon.svg' onClick={onDel}></Icon30px20pxPointer> */}
          </StyledDiv>
        </DivWithDisplay>
      </DivWithDisplay>
      </CaseCardContainer>
    )
    
    
}

CaseCard.defaultProps = {
    CaseNumber:"#001",
    DateText:"",
    TimeText:"",
    ClientName:"John Smith",
    Status:0,
    StatusText:"Default",
    CaseId:0,
    DefaultColor:"#F53838",
    StatusColor:"grey",
    EstimatorName:"Estimator"
}

export default CaseCard