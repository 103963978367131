/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import {
    GetCityList,
    GetMetroList,
} from "../../../API_Functions/Utility_Functions";
import { Header } from "../../../StylesheetComps/AuthSheet";
import {
    FlexColumnLeftAlign,
    FlexRowCont,
    FlexRowContAutox100PercentMobileColumn
} from "../../../StylesheetComps/Cont";
import {
    DatalistMedium,
    DatalistOption,
    EstimatorDiv,
    GreenNavigationButton,
    GreyNavigationButton,
    Section1,
    TextAreaRegular,
    TextInputReusable
} from "../../../StylesheetComps/LandscapeForm";
import { FlexColumnCont } from "../../../StylesheetComps/Menu";
import {
    LeagueSpartanTiny,
    RegularTextBoldRed
} from "../../../StylesheetComps/Titles";
import { AddCaseOBO, SetCaseTo_Submit } from "../../../API_Functions/CaseQuote_Functions";
import { GetStoreByMetro } from "../../../API_Functions/Store_Functions";
// CSS for modal
const modalStyles = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '50px',
  height: '90vh',
  zIndex: 1000,
  overflowY: 'auto', // This line allows the content to be scrollable if it overflows
  WebkitOverflowScrolling: 'touch', // This line allows smooth scrolling on iOS devices
};


const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1000,
};

const QuickProjectForm = ({ open, onNext, onBack, onPreview, onPersonalInfo, onClose }) => {

    const [isModalOpen, setIsModalOpen] = useState(true);
    const history = useHistory();
    const params = useParams();
    const projectInfoMargin = "30px";
    const mobileHeaderWidth = "90px";

    const [Title, setTitle] = useState("");
    const [homeownerUsername, setHomeownerUsername] = useState("");
    const [cityList, setCityList] = useState([]);
    const [metroList, setMetroList] = useState([]);
    const [ErrorText, setErrorText] = useState("");
    const [firstName, setFirstName] = useState("");
    const [name, setName] = useState("");

    let newProjectObj = useRef({
        FullName: "",
        Email: "",
        StreetAddress:"",
        City: "",
        City_Id: 0,
        Region: "",
        Metro_Id: 0,
        Phone: "",
        PropertyType:"",
        Details:"",
        RequestName: ""
    });

    const GetLocationInfo = async () => {
        let metrodata = await GetMetroList();
        let citydata = await GetCityList(newProjectObj.current.Metro_Id);
        setCityList(citydata);
        setMetroList(metrodata);
    };

    const UpdateCityList = async (metroid) => {
        if (metroid !== 0 && metroid !== 3) {
            newProjectObj.current.City = "";
            newProjectObj.current.City_Id = 0;
            let citydata = await GetCityList(metroid);
            setCityList(citydata);
        }
    };

    useEffect(() => {
        GetLocationInfo();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // const DefinePersonalInfo = async () => {
    //     const D = new Date();
    //     const currentYear = D.getFullYear();
    //     const currentMonth = ("0" + (D.getMonth() + 1)).slice(-2);
    //     const currentDay = ("0" + D.getDate()).slice(-2);
    //     const currentHour = ("0" + D.getHours()).slice(-2);
    //     const currentMinute = ("0" + D.getMinutes()).slice(-2);   
    //     newProjectObj.current.Name = firstName + " " + lastName;
    //     if (
    //         firstName !== null &&
    //         firstName !== "" &&
    //         lastName !== null &&
    //         lastName !== "" &&
    //         newProjectObj.current.Name !== null &&
    //         newProjectObj.current.Name !== "" &&
    //         newProjectObj.current.Street !== null &&
    //         newProjectObj.current.Street !== "" &&
    //         newProjectObj.current.CityId !== 0 &&
    //         newProjectObj.current.Phone !== null &&
    //         newProjectObj.current.Phone !== "" &&
    //         newProjectObj.current.Email !== null &&
    //         newProjectObj.current.Email !== "" &&
    //         Title !== null &&
    //         Title !== "" &&
    //         newProjectObj.current.Metro_Id !== 0 &&
    //         newProjectObj.current.Metro_Id !== 3
    //     ) {
    //         newProjectObj.current.Tittle = Title
    //         newProjectObj.current.CreateTime = (currentYear +
    //             "-" +
    //             currentMonth +
    //             "-" +
    //             currentDay +
    //             "T" +
    //             currentHour +
    //             ":" +
    //             currentMinute);
    //         newProjectObj.current.UpdateTime = (currentYear +
    //             "-" +
    //             currentMonth +
    //             "-" +
    //             currentDay +
    //             "T" +
    //             currentHour +
    //             ":" +
    //             currentMinute);
    //         newProjectObj.current.Description = ""
    //         newProjectObj.current.ImageUrl = []
    //         const stores = await GetStoreByMetro(newProjectObj.current.Metro_Id)
    //         if(stores !== null){
    //         newProjectObj.current.Store_Id = stores[0].Id
    //         const data = await AddCaseOBO(newProjectObj.current, homeownerUsername )
    //         if(data){
    //             const submit = await SetCaseTo_Submit(data.Case.Id)
    //             if(submit !== null){
    //                 onClose()
    //             }
    //         } else {
    //             setErrorText("Username not found");
    //         }
    //         }
      
    //     } else {
    //         setErrorText("Please fill in all of the boxes");
    //         window.scrollTo(0, 0);
    //     }
    // };



    const DefinePersonalInfo = async () => {
      newProjectObj.current.RequestName = (JSON.parse(sessionStorage.getItem("userInfo")).Name)
      const automationData = newProjectObj.current
    const resp = await axios.post(window.$DBURL+'/users/forwardToZapier', automationData).then(response => {
      history.push('/home')
  })
  .catch(error => {
      console.error("Error sending data to backend:", error);
  });

    }

    const SelectCity = async (e) => {
      newProjectObj.current.City_Id = e.target.value;
  
      const selectedCity = cityList.find(o => o.Id === Number(e.target.value));
      
      if (selectedCity) {
          newProjectObj.current.City = selectedCity.Name;
      }
  };
  

    const SelectMetro = async (e) => {
      newProjectObj.current.Metro_Id = e.target.value;
      
      const selectedMetro = metroList.find(o => o.Id === Number(e.target.value));
      
      if (selectedMetro) {
          newProjectObj.current.Region = selectedMetro.Name;
      } else {
      }
  };
  

    return (
        <>
            {isModalOpen && (
                <div style={overlayStyles}>
                    <div style={modalStyles}>
                        <EstimatorDiv>
                        <Section1>
          <Header fontsize="22px" margintop="0%">
          Create a Quick Project on Behalf of a Homeowner:
          </Header>
          <RegularTextBoldRed>{ErrorText}</RegularTextBoldRed>
{/*     
          <FlexRowContAutox100PercentMobileColumn>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="projectname">
                Username of Homeowner (Find on ClickUp)
              </LeagueSpartanTiny>
              <TextInputReusable
                id="projectname"
                mobilemarginright={projectInfoMargin}
                placeholder={"Homeowner Username"}
                onChange={(e) => {
                  setHomeownerUsername(e.target.value);
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
          </FlexRowContAutox100PercentMobileColumn> */}
    
          <FlexRowContAutox100PercentMobileColumn>
            {/* <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="projectname">
                Project Name
              </LeagueSpartanTiny>
              <TextInputReusable
                id="projectname"
                mobilemarginright={projectInfoMargin}
                placeholder={"Project Name (Ex. Robyn's Backyard)"}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign> */}
               <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="name">
                Name
              </LeagueSpartanTiny>
              <TextInputReusable
                id="name"
                placeholder={"Full Name"}
                defaultValue={name}
                onChange={(e) => {
                  newProjectObj.current.FullName = e.target.value;
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="address">Address</LeagueSpartanTiny>
              <TextInputReusable
                id="address"
                placeholder={"Address"}
                onChange={(e) => {
                  newProjectObj.current.StreetAddress = e.target.value;
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
          </FlexRowContAutox100PercentMobileColumn>
    
          {/* <FlexRowContAutox100PercentMobileColumn>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="firstname">
                First Name
              </LeagueSpartanTiny>
              <TextInputReusable
                id="firstname"
                placeholder={"First Name"}
                defaultValue={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
         
          </FlexRowContAutox100PercentMobileColumn> */}
    
          <FlexRowContAutox100PercentMobileColumn>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="cellphone">
                Phone Number
              </LeagueSpartanTiny>
              <TextInputReusable
                type="tel"
                id="cellphone"
                placeholder={"Cell Phone"}
                defaultValue={newProjectObj.current.Phone}
                onChange={(e) => {
                  newProjectObj.current.Phone = e.target.value;
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="emailaddress">
                Email Address
              </LeagueSpartanTiny>
              <TextInputReusable
                type="email"
                id="emailaddress"
                placeholder={"Email Address"}
                defaultValue={newProjectObj.current.Email}
                onChange={(e) => {
                  newProjectObj.current.Email = e.target.value;
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
          </FlexRowContAutox100PercentMobileColumn>

          <FlexRowContAutox100PercentMobileColumn>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="type">
                Home Type (Single Family, Townhouse, etc.)
              </LeagueSpartanTiny>
              <TextInputReusable
                id="type"
                placeholder={"Home Type"}
                defaultValue={newProjectObj.current.PropertyType}
                onChange={(e) => {
                  newProjectObj.current.PropertyType = e.target.value;
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="details">
                Notes
              </LeagueSpartanTiny>
              <TextInputReusable
                type="email"
                id="details"
                placeholder={"Notes"}
                defaultValue={newProjectObj.current.Details}
                onChange={(e) => {
                  newProjectObj.current.Details = e.target.value;
                }}
              ></TextInputReusable>
            </FlexColumnLeftAlign>
          </FlexRowContAutox100PercentMobileColumn>
    
    
          <FlexRowContAutox100PercentMobileColumn>
          <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="metros">Region</LeagueSpartanTiny>
              <DatalistMedium
                defaultValue={newProjectObj.current.Region}
                id="metros"
                onChange={(e) => {
                  SelectMetro(e);
                  UpdateCityList(e.target.value);
                }}
              >
                <DatalistOption value={0} >Select Region</DatalistOption>
                {metroList.map((o, i) => (
                  <DatalistOption
                    key={o.Id}
                    value={o.Id}
                    defaultValue={o.Id === newProjectObj.current.Metro_Id}
                    selected={o.Id === newProjectObj.current.Metro_Id}
                  >
                    {o.Name}
                  </DatalistOption>
                ))}
              </DatalistMedium>
            </FlexColumnLeftAlign>

            <FlexColumnLeftAlign>
              <LeagueSpartanTiny htmlFor="cities">City</LeagueSpartanTiny>
    
              <DatalistMedium
                defaultValue={newProjectObj.current.City}
                id="cities"
                onChange={(e) => {
                  SelectCity(e);
                }}
              >
                <DatalistOption value={0}>Select City</DatalistOption>
                {cityList.map((o, i) => (
                  <DatalistOption
                    id="city"
                    defaultValue={o.Id === newProjectObj.current.City_Id}
                    selected={o.Id === newProjectObj.current.City_Id}
                    key={o.Id}
                    value={o.Id}
                  >
                    {o.Name}
                  </DatalistOption>
                ))}
              </DatalistMedium>
            </FlexColumnLeftAlign>

          </FlexRowContAutox100PercentMobileColumn>
    
          <br></br>
    
          <FlexColumnCont
            height="8%"
            mobilewidth="auto"
            margin="0"
            margintop="auto"
            flexdirection="row"
            justifycontent="space-around"
            width="100%"
          >
            <GreyNavigationButton
              onClick={() => {
                onClose();
              }}
            >
              Close
            </GreyNavigationButton>
            <GreenNavigationButton
              onClick={() => {
                DefinePersonalInfo();
              }}
            >
              Submit
            </GreenNavigationButton>
          </FlexColumnCont>
        </Section1>
                        </EstimatorDiv>
                    </div>
                </div>
            )}
        </>
    );
            }
            

//     return (
//         <>
//             {isModalOpen && (
//                 <div style={overlayStyles}>
//                     <div style={modalStyles}>
//                         <EstimatorDiv>
//                         <Section1>
//           <Header fontsize="22px" margintop="0%">
//           Create a Project on Behalf of a Homeowner:
//           </Header>
//           <RegularTextBoldRed>{ErrorText}</RegularTextBoldRed>

//           <FlexRowCont>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="projectname">
//                 Username of Homeowner (Find on ClickUp)
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 id="projectname"
//                 mobilemarginright={projectInfoMargin}
//                 placeholder={"Homeowner Username"}
//                 onChange={(e) => {
//                   setHomeownerUsername(e.target.value);
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//           </FlexRowCont>

//           <FlexRowCont>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="projectname">
//                 Project Name
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 id="projectname"
//                 mobilemarginright={projectInfoMargin}
//                 placeholder={"Project Name (Ex. Robyn's Backyard)"}
//                 onChange={(e) => {
//                   setTitle(e.target.value);
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="address">Address</LeagueSpartanTiny>
//               <TextInputReusable
//                 id="address"
//                 placeholder={"Address"}
//                 onChange={(e) => {
//                   ContactInfo.current.Street = e.target.value;
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//           </FlexRowCont>

//           <FlexRowCont>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="firstname">
//                 First Name
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 id="firstname"
//                 placeholder={"First Name"}
//                 defaultValue={firstName}
//                 onChange={(e) => {
//                   setFirstName(e.target.value);
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="lastname">
//                 Last Name
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 id="lastname"
//                 placeholder={"Last Name"}
//                 defaultValue={lastName}
//                 onChange={(e) => {
//                   setLastName(e.target.value);
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//           </FlexRowCont>

//           <FlexRowCont>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="cellphone">
//                 Phone Number
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 type="tel"
//                 id="cellphone"
//                 placeholder={"Cell Phone"}
//                 defaultValue={ContactInfo.current.Phone}
//                 onChange={(e) => {
//                   ContactInfo.current.Phone = e.target.value;
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="emailaddress">
//                 Email Address
//               </LeagueSpartanTiny>
//               <TextInputReusable
//                 type="email"
//                 id="emailaddress"
//                 placeholder={"Email Address"}
//                 defaultValue={ContactInfo.current.Email}
//                 onChange={(e) => {
//                   ContactInfo.current.Email = e.target.value;
//                 }}
//               ></TextInputReusable>
//             </FlexColumnLeftAlign>
//           </FlexRowCont>

//           <FlexRowCont>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="cities">City</LeagueSpartanTiny>

//               <DatalistMedium
//                 defaultValue={ContactInfo.current.City}
//                 id="cities"
//                 onChange={(e) => {
//                   SelectCity(e);
//                 }}
//               >
//                 <DatalistOption value={0}>Select City</DatalistOption>
//                 {cityList.map((o, i) => (
//                   <DatalistOption
//                     id="city"
//                     defaultValue={o.Id === ContactInfo.current.City_Id}
//                     selected={o.Id === ContactInfo.current.City_Id}
//                     key={o.Id}
//                     value={o.Id}
//                   >
//                     {o.Name}
//                   </DatalistOption>
//                 ))}
//               </DatalistMedium>
//             </FlexColumnLeftAlign>
//             <FlexColumnLeftAlign>
//               <LeagueSpartanTiny htmlFor="metros">Region</LeagueSpartanTiny>
//               <DatalistMedium
//                 defaultValue={ContactInfo.current.Metro}
//                 id="metros"
//                 onChange={(e) => {
//                   SelectMetro(e);
//                   UpdateCityList(e.target.value);
//                 }}
//               >
//                 <DatalistOption value={0}>Select Region</DatalistOption>
//                 {metroList.map((o, i) => (
//                   <DatalistOption
//                     key={o.Id}
//                     value={o.Id}
//                     defaultValue={o.Id === ContactInfo.current.Metro_Id}
//                     selected={o.Id === ContactInfo.current.Metro_Id}
//                   >
//                     {o.Name}
//                   </DatalistOption>
//                 ))}
//               </DatalistMedium>
//             </FlexColumnLeftAlign>
//           </FlexRowCont>

//           <br></br>

//           <FlexColumnCont
//             height="8%"
//             mobilewidth="auto"
//             margin="0"
//             margintop="auto"
//             flexdirection="row"
//             justifycontent="space-around"
//             width="100%"
//           >
//             <GreyNavigationButton
//               onClick={() => {
//                 history.push("/storelistestimate/" + params.Id);
//               }}
//             >
//               Back
//             </GreyNavigationButton>
//             <GreenNavigationButton
//               onClick={() => {
//                 DefinePersonalInfo();
//               }}
//             >
//               Next
//             </GreenNavigationButton>
//           </FlexColumnCont>
//         </Section1>
//                         </EstimatorDiv>
//                     </div>
//                 </div>
//             )}
//         </>
//     );
// };

QuickProjectForm.defaultProps = {
    onChange: () => {},
    onPreview: () => {},
    onClose: () => {},
    onPersonalInfo: () => {},
    fullName: "",
    addressofUser: "",
    emailofUser: "",
    phoneofUser: "",
  };

export default QuickProjectForm;
