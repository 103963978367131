import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { GetQuoteById, UpdateQuoteItem, UpdateQuoteTool } from '../../../API_Functions/CaseQuote_Functions';
import { decimalHoursToTimeString } from '../../../API_Functions/Utility_Functions';
import { CategoryTypes } from '../../../StylesheetComps/CategoryTypes';
import { FlexContCenterAlign } from '../../../StylesheetComps/Cont';
import { FlexColumnDiv } from '../../../StylesheetComps/Div';
import { DatalistOption, SelectField, TextAreaAutoResize } from '../../../StylesheetComps/LandscapeForm';

const SpeechBubbleContainer = styled.div`
  display: ${(props) => (props.display ? props.display : "block")};
  align-items: center;
  @media (max-width: 768px) {
    width: 100vw; /* or whatever percentage suits your design */
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SpeechBubble = styled.div`
  position: relative;
  margin-top:20px;
  margin-bottom:-20px;
  width: 300px;
  height: auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1) transparent transparent transparent;
    transform: translate(0, 1px);
  }

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #f9f9f9 transparent transparent transparent;
  }

  @media (max-width: 768px) {
    width: 60vw; /* or whatever percentage suits your design */
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const InputBox = styled.input`
width: 100%;
  border: none;
  border-bottom: 2px solid #ddd;
  border-radius: 5px;
  padding:10px;
  outline: none;
  font-size: 18px; // Increase size
  margin-right: 10px;
  font-family: Avenir;
`;

const ImageContainer = styled.div`
display: flex;
flex-direction:column;
justify-content: space-evenly;
width: auto;
margin-left:20px;
@media (max-width: 768px) {
  margin-left: auto;
}
`;

const ImageButton = styled.img`
  width: 24px;
  height: 24px;
  margin:5px;
  cursor: pointer;
`;


const SpeechBubbleComponent = ({ saveItemInfo, cancelItemChange, itemObj, display, fieldType, RefreshComponentState, onCloseBubble }) => {

  const [inputValue, setInputValue] = useState({ installTime: '', wheelbarrowTime: '' });
  const [inputInteracted, setInputInteracted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taglist, setTaglist] = useState([]);
  const containerRef = useRef(null);

  const GetQuoteTaglist = async (quote_id) => {
    if (quote_id !== 0 && quote_id !== undefined) {
      try {
        const data = await GetQuoteById(quote_id);
        if (data && data.quote) {
          setTaglist(data.quote.Tags);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        // 3. If the click is outside, close the component
        cancelItemChange(); // Assuming cancelItemChange is the method to close it
      }
    }

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]); // Empty dependency array means this effect runs once on mount and unmount


  const params = useParams()

  const determineLeftMargin = () => {
    if (window.innerWidth <= 768) {
      return '0px'; // Center it on mobile
    }  
    switch (fieldType) {
      case 'Quantity':
        return '-20vw';
      case 'Name':
        return '-20vw';
      case 'Notes':
          return '-20vw';
      case 'Install':
        return '39vw';
      case 'Rental Hours':
          return '39.5vw';
      case 'Tag':
          return '54vw';
      default:
        return '50px'; // You can set a default value
    }
  };

  const updateItemOnEstimate = async () => {
    setIsLoading(true)
    switch(fieldType) {
      case 'Quantity':
        itemObj.Quant = inputValue; 
        if(!itemObj.Tool_Id) {
        await UpdateQuoteItem(itemObj); 
        } else {
        await UpdateQuoteTool(itemObj);   
        }
        break;
  
      case 'Name':
        itemObj.Name = inputValue; 
        var finalValue = itemObj.Quant;
        var denominator = itemObj.Denominator;
        var allowance = itemObj.Allowance;

        var intermediateValue;
        if (allowance === 0) {
            intermediateValue = finalValue; // No adjustment needed if allowance is 0
        } else {
            intermediateValue = finalValue / (1 + (allowance / 100));
        }

        var originalQuant = intermediateValue * denominator;
     
        // Assign the result back to the object or use it as needed
        itemObj.Quant = originalQuant;
        await UpdateQuoteItem(itemObj);
        break;

        case 'Notes':
        itemObj.Notes = inputValue; 
        
        var finalValue = itemObj.Quant;
        var denominator = itemObj.Denominator;
        var allowance = itemObj.Allowance;

        var intermediateValue;
        if (allowance === 0) {
            intermediateValue = finalValue; // No adjustment needed if allowance is 0
        } else {
            intermediateValue = finalValue / (1 + (allowance / 100));
        }

        var originalQuant = intermediateValue * denominator;
     
        // Assign the result back to the object or use it as needed
        itemObj.Quant = originalQuant;
        if(!itemObj.Tool_Id) {  
        await UpdateQuoteItem(itemObj);
        } else {
        await UpdateQuoteTool(itemObj);
        }
        break;

        case 'Rental Hours':
        itemObj.RentalLength = inputValue; 
        await UpdateQuoteTool(itemObj);
        break;

        case 'Tag':
          itemObj.Tag_Id = inputValue; 

          var finalValue = itemObj.Quant;
          var denominator = itemObj.Denominator;
          var allowance = itemObj.Allowance;

          var intermediateValue;
          if (allowance === 0) {
              intermediateValue = finalValue; // No adjustment needed if allowance is 0
          } else {
              intermediateValue = finalValue / (1 + (allowance / 100));
          }

          var originalQuant = intermediateValue * denominator;
       
          // Assign the result back to the object or use it as needed
          itemObj.Quant = originalQuant;
          await UpdateQuoteItem(itemObj);
          break;
  
      case 'Install':
        const { installTime, wheelbarrowTime, installItem, wheelbarrowItem } = inputValue;
        if (installTime && installItem) {
          installItem.Quant = installTime; 
          await UpdateQuoteItem(installItem); 
        }
  
        if (wheelbarrowTime && wheelbarrowItem) {
          wheelbarrowItem.Quant = wheelbarrowTime; 
          await UpdateQuoteItem(wheelbarrowItem); 
        }
        break;

        case 'Removal Quantity':
          const { removalTime, removalWheelbarrowTime, removalItem, removalWheelbarrowItem } = inputValue;
          if (removalTime && removalItem) {
            removalItem.Quant = removalTime; 
            await UpdateQuoteItem(removalItem); 
          }
    
          if (removalWheelbarrowTime && removalWheelbarrowItem) {
            removalWheelbarrowItem.Quant = removalWheelbarrowTime; 
            await UpdateQuoteItem(removalWheelbarrowItem); 
          }
          break;
        default:
        break;
    }
    setIsLoading(false)
    RefreshComponentState();
  };
  
  
  
  


  const handleChange = (key, value) => {
    // Convert the input to a number
    const parsedValue = parseFloat(value);
    // Ensure that the value is 0 or more
    const positiveValue = Math.max(0, parsedValue);
    setInputValue({...inputValue, [key]: positiveValue.toString() || ''});
  };

  const renderInputs = () => {
    switch (fieldType) {
      case 'Quantity':
        return (
          <div>
            <label>Quantity, <i> Input Unit: </i> {`(${itemObj?.Unit !== undefined ? itemObj?.Unit : 'N/A'})`}</label>
            <InputBox
            type={window.$ISMOBILE === true ? 'text' : 'type'}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        );
      case 'Name':
        return (
          <div>
            <label>Name</label>
            <InputBox
              value={inputValue}
              style={{ fontSize: '14px' }}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        );
        case 'Notes':
          return (
            <div>
              <label>Notes</label>
              <TextAreaAutoResize
                value={inputValue}
                style={{ fontSize: '14px' }}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
          );
          case 'Rental Hours':
            return (
              <div>
                <label>Rental Hours</label>
                <InputBox
            type={window.$ISMOBILE === true ? 'text' : 'type'}
            value={inputValue}
                  style={{ fontSize: '14px' }}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </div>
            );
            case 'Tag':
              return (
                <FlexContCenterAlign>
                  <FlexColumnDiv>
                  <label>Tag</label>
                  <SelectField
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  >
                     <DatalistOption value={0}>General Materials</DatalistOption>
                    {taglist.map((o, i) => (
                      <DatalistOption value={o.Id} key={i}>
                        {o.TagName}
                      </DatalistOption>
                    ))}
                  </SelectField>
                  </FlexColumnDiv>
                </FlexContCenterAlign>
              );
      case 'Install':
        const { installTime, wheelbarrowTime, installItem, wheelbarrowItem } = inputValue;
        
        // If both installTime and wheelbarrowTime are missing, return null to not render anything
        if ((installTime === undefined || installTime === null || installTime === '') &&
        (wheelbarrowTime === undefined || wheelbarrowTime === null || wheelbarrowTime === '')) {
        return null;
    }
            
        return (
            <div>
            {(inputInteracted || installTime === 0 || installTime) && (
              <div>
                <label>Install Time "{decimalHoursToTimeString(installTime || '')}"</label>
                <InputBox
                  type={window.$ISMOBILE === true ? 'text' : 'text'}
                  step='0.01'
                  min='0' 
                  defaultValue={(installTime || '')}
                  onChange={(e) => {if(e.target.value !== ''){ handleChange('installTime', e.target.value)} else {handleChange('installTime', 0.01)}}}
                />
              </div>
            )}
            {(inputInteracted || wheelbarrowTime === 0 || wheelbarrowTime) && (
              <div>
                <label>{wheelbarrowItem.Notes} "{decimalHoursToTimeString(wheelbarrowTime || '')}"</label>
                <InputBox
                  type={window.$ISMOBILE === true ? 'text' : 'text'}
                  step='0.01'
                  min='0' 
                  defaultValue={(wheelbarrowTime || '')}
                  onChange={(e) => {if(e.target.value !== ''){ handleChange('wheelbarrowTime', e.target.value)} else {handleChange('wheelbarrowTime', 0.01)}}}
                />
              </div>
            )}
          </div>
          );
          
          case 'Removal Quantity':
        const { removalTime, removalWheelbarrowTime, removalItem, removalWheelbarrowItem } = inputValue;
        
        // If both installTime and wheelbarrowTime are missing, return null to not render anything
        if ((removalTime === undefined || removalTime === null || removalTime === '') &&
        (removalWheelbarrowTime === undefined || removalWheelbarrowTime === null || removalWheelbarrowTime === '')) {
        return null;
    }
            
        return (
            <div>
            {(inputInteracted || removalTime === 0 || removalTime) && (
              <div>
                <label>Removal Time "{decimalHoursToTimeString(removalTime || '')}"</label>
                <InputBox
                  type='type'
                  step='0.01'
                  min='0' 
                  defaultValue={(removalTime || '')}
                  onChange={(e) => {if(e.target.value !== ''){ handleChange('removalTime', e.target.value)} else {handleChange('removalTime', 0.01)}}}
                />
              </div>
            )}
            {(inputInteracted || removalWheelbarrowTime === 0 || removalWheelbarrowTime) && (
              <div>
                <label>{removalWheelbarrowItem.Notes} "{decimalHoursToTimeString(removalWheelbarrowTime || '')}"</label>
                <InputBox
                  type='type'
                  step='0.01'
                  min='0' 
                  defaultValue={(removalWheelbarrowTime || '')}
                  onChange={(e) => {if(e.target.value !== ''){ handleChange('removalWheelbarrowTime', e.target.value)} else {handleChange('removalWheelbarrowTime', 0.01)}}}
                />
              </div>
            )}
          </div>
          );

      default:
        return null;
    }
  };

  useEffect(() => {
    switch (fieldType) {
      case 'Quantity':
          setInputValue(itemObj?.Quant || 0);
        break;
      case 'Name':
          setInputValue(itemObj?.Name);
        break;
      case 'Notes':
          setInputValue(itemObj?.Notes);
        break;
      case 'Rental Hours':
          setInputValue(itemObj?.RentalLength);
        break;
        case 'Tag':
          GetQuoteTaglist(params.quoteId)
          setInputValue(itemObj?.Tag_Id);
          break;
        case 'Install':
            let installTime = '';
            let wheelbarrowTime = '';
            let installItem = null;
            let wheelbarrowItem = null;
      
            itemObj?.RelatedItems?.forEach(item => {
              if (item.CateType === CategoryTypes.Labor) {
                if (item.Notes.startsWith('Install')) {
                  installTime = item.Quant;
                  installItem = item;
                } else if (item.Notes.startsWith('Needed to operate')) {
                  wheelbarrowTime = item.Quant;
                  wheelbarrowItem = item;
                }
              }
            });
      
            setInputValue({ installTime, wheelbarrowTime, installItem, wheelbarrowItem });
            break;
        case 'Removal Quantity':
          let removalTime = '';
          let removalWheelbarrowTime = '';
          let removalItem = null;
          let removalWheelbarrowItem = null;
    
          itemObj?.RelatedItems?.forEach(item => {
            if (item.CateType === CategoryTypes.Labor) {
              if (item.Notes.startsWith('Install')) {
                removalTime = item.Quant;
                removalItem = item;
              } else if (item.Notes.startsWith('Needed to operate')) {
                removalWheelbarrowTime = item.Quant;
                removalWheelbarrowItem = item;
              }
            }
          });
    
          setInputValue({ removalTime, removalWheelbarrowTime, removalItem, removalWheelbarrowItem });
      default:
        break;
    }
  }, [fieldType, itemObj]);  

  return (
    // isLoading === true ? (
    //   <Backdrop style={{ zIndex: 1000 }} open>
    //   <CircularProgress color="inherit" />
    // </Backdrop>
    // ) :
    <SpeechBubbleContainer ref={containerRef}  display={display}>
    {renderInputs() ? (
      <SpeechBubble style={{ marginLeft: determineLeftMargin() }}>
        {renderInputs()}
        <ImageContainer>
          <ImageButton src='/Check Mark.svg' onClick={() => { setInputInteracted(false); updateItemOnEstimate(); onCloseBubble(); }} alt='Save' />
          <ImageButton src='/Close.svg' onClick={() => { setInputInteracted(false); cancelItemChange() }} alt='Cancel' />
        </ImageContainer>
      </SpeechBubble>
    ) : null}
  </SpeechBubbleContainer>
  );  
};

SpeechBubbleComponent.defaultProps = {
    updateItemOnEstimate: () => {},
    cancelItemChange: () => {},
    RefreshComponentState: () => {},
    onCloseBubble: () => {},
}

export default SpeechBubbleComponent;
