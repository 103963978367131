import React, { useState } from "react";
import { Checkbox } from "@material-ui/core";
import { ColorPalette } from "../../../StylesheetComps/Colors";
import { RegularResponsiveButton } from "../../../StylesheetComps/Components";
import {
  FlexDiv,
  FlexRowCont100PercentSpaceBetween,
  FlexRowContFlexStart,
  LeftItemWindowFixed,
} from "../../../StylesheetComps/Cont";
import {
  TextAreaLarge,
  TextInputReusable,
} from "../../../StylesheetComps/LandscapeForm";
import { FlexColumnCont } from "../../../StylesheetComps/Menu";
import { FixedOverlay200vh } from "../../../StylesheetComps/OverlayCont";
import { RegularText, RegularTextBold } from "../../../StylesheetComps/Titles";
import { FlexDivSpaceBetween } from "../../../StylesheetComps/Div";

const EmailWindow = ({
  open,
  onClose,
  confirmtext,
  leftbuttontext,
  rightbuttontext,
  leftbuttoncolor,
  rightbuttoncolor,
  left,
  LeftFunction,
  closeWindow,
  RightFunction,
  width,
  top,
  projectEmail,
  estimatorEmail,
  projectName,
  onSendEmail,
}) => {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState(projectEmail);
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [subject, setSubject] = useState(`NRC Project Estimate ${projectName}`);
  const [link, setLink] = useState(false);
  const [signature, setSignature] = useState("blank");
  const [text, setText] = useState(`
Thank you for reaching out to New Rhodes for your project!

Please see the following link to view your online estimate.

If you have any questions, please feel free to contact our team.

`);

  const handleSendEmail = () => {
    onSendEmail(
      from,
      to,
      cc,
      bcc,
      subject,
      link ? true : false,
      text,
      signature
    );
  };

  if (!open) return null;
  return (
    <FixedOverlay200vh>
      <FlexRowContFlexStart>
        <LeftItemWindowFixed left={left} top={top} width={width}>
          <FlexDivSpaceBetween>
            <RegularTextBold>From:</RegularTextBold>
            <TextInputReusable
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </FlexDivSpaceBetween>

          <FlexDivSpaceBetween>
            <RegularTextBold>To:</RegularTextBold>
            <TextInputReusable
              value={to}
              onChange={(e) => setTo(e.target.value)}
            />
          </FlexDivSpaceBetween>

          <FlexDivSpaceBetween>
            <RegularTextBold>CC:</RegularTextBold>
            <TextInputReusable
              value={cc}
              onChange={(e) => setCc(e.target.value)}
            />
          </FlexDivSpaceBetween>

          <FlexDivSpaceBetween>
            <RegularTextBold>BCC:</RegularTextBold>
            <TextInputReusable
              value={bcc}
              onChange={(e) => setBcc(e.target.value)}
            />
          </FlexDivSpaceBetween>

          <FlexDivSpaceBetween>
            <RegularTextBold>Subject:</RegularTextBold>
            <TextInputReusable
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </FlexDivSpaceBetween>

          <FlexDiv>
            <RegularTextBold>Include Sharable Estimate Link?</RegularTextBold>
            <Checkbox
              checked={link}
              onChange={(e) => setLink(e.target.checked)}
            />
          </FlexDiv>
          {/* <RegularText>Hello,</RegularText> */}
          <FlexRowCont100PercentSpaceBetween>
            <RegularTextBold></RegularTextBold>
            <TextAreaLarge
            height='200px'
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </FlexRowCont100PercentSpaceBetween>
          <RegularText>{confirmtext}</RegularText>
          {/* <FlexDivSpaceBetween>
            <RegularTextBold>Signature:</RegularTextBold>
            <TextInputReusable
              value={signature}
              onChange={(e) => setSignature(e.target.value)}
            />
          </FlexDivSpaceBetween> */}
          <FlexRowCont100PercentSpaceBetween>
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryRed}
              onClick={() => {
                closeWindow();
              }}
            >
              Cancel
            </RegularResponsiveButton>{" "}
            <RegularResponsiveButton
              bgcolor={ColorPalette.PrimaryButtonBlue}
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to send this email?")
                ) {
                  handleSendEmail();
                }
              }}
            >
              Send
            </RegularResponsiveButton>
          </FlexRowCont100PercentSpaceBetween>

          <FlexColumnCont></FlexColumnCont>
        </LeftItemWindowFixed>
      </FlexRowContFlexStart>
    </FixedOverlay200vh>
  );
};

EmailWindow.defaultProps = {
  RightFunction: () => {},
  closeWindow: () => {},
  onSendEmail: () => {},
  rightbuttoncolor: ColorPalette.PrimaryButtonBlue,
  leftbuttoncolor: ColorPalette.PrimaryButtonBlue,
  left: "40%",
  top: "5%",
};

export default EmailWindow;
